<template>
  <div class="menu-common">
    <el-menu router active-text-color="#f19403" :default-active="activeIndex">
      <el-menu-item
        v-for="item in menuItemData"
        :key="item.index"
        :index="item.index"
      >
        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { menuItemData } from '../data';
export default {
  name: 'MenuCommon',
  data() {
    return {
      activeIndex: undefined,
      menuItemData,
    };
  },
  watch: {
    '$route.params.nav'() {
      this.activeIndex = this.$route.params.nav;
    },
  },
  mounted() {
    this.activeIndex = this.$route.params.nav;
  },
};
</script>
<style>
.el-menu {
  height: 100%;
  border-right: none;
  overflow-y: auto;
}
.el-menu-item {
  height: 30px;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.el-menu-item,
.el-submenu__title {
  line-height: 30px;
}
.el-menu-item:hover {
  background-color: #eceff1;
}
.el-menu-item.is-active {
  background: #f1940317;
}
</style>
