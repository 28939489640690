<template>
  <div class="doc-content">
    <div class="content-left">
      <div class="doc">
        <!-- 文档内容 -->
        <component :is="contentPanel" />

        <!-- 上一页下一页 -->
        <div class="page">
          <div v-if="selectIndex !== 0" class="previous" @click="goPrevious">
            <div><i class="el-icon-back"></i></div>
            <div>
              <div class="tip">{{ $t('doc.previous') }}</div>
              <div>{{ menuItemData[selectIndex - 1]?.title }}</div>
            </div>
          </div>
          <div
            v-if="selectIndex !== menuItemData.length - 1"
            class="next"
            @click="goNext"
          >
            <div>
              <div class="tip">{{ $t('doc.next') }}</div>
              <div>{{ menuItemData[selectIndex + 1]?.title }}</div>
            </div>
            <div><i class="el-icon-right"></i></div>
          </div>
        </div>
      </div>
    </div>

    <!-- h2锚点 -->
    <div class="anchor" v-if="stepData.length">
      <div class="anchor-title">{{ $t('doc.content') }}</div>
      <ul class="step-ul">
        <li
          class="flex_align_item_center"
          v-for="(item, index) in stepData"
          :key="index"
          :class="{ 'step-active': activeBtn == index }"
          @click="goAnchor(index)"
        >
          <div class="step-label">{{ item }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const loadComponent = (lang, nav) => import(`./${lang}/${nav}`);
import { menuItemData } from './data';

export default {
  name: 'DocContent',
  components: {},
  data() {
    return {
      contentPanel: null, // 定义组件
      // 分页
      currentRoute: null,
      menuItemData,
      //锚点
      activeBtn: 0,
      stepData: [],
    };
  },
  computed: {
    selectIndex: function () {
      return this.menuItemData.findIndex(
        item => item.index === this.currentRoute
      );
    },
  },
  watch: {
    $route: {
      handler(value) {
        const { lang, nav } = value.params;
        this.currentRoute = nav;
        loadComponent(lang, nav).then(component => {
          this.contentPanel = component.default;
          this.initStepData();
        });
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    goPrevious() {
      this.$router.push(
        `/doc/${this.$i18n.locale}/${
          this.menuItemData[this.selectIndex - 1].index
        }`
      );
    },
    goNext() {
      this.$router.push(
        `/doc/${this.$i18n.locale}/${
          this.menuItemData[this.selectIndex + 1].index
        }`
      );
    },
    initStepData() {
      this.activeBtn = 0;
      this.getStepData();
    },
    getStepData() {
      this.$nextTick(() => {
        this.stepData = [...document.querySelectorAll('h2')].map(
          item => item.innerText
        );
      });
    },
    //锚点跳转
    goAnchor(index) {
      this.activeBtn = index;
      document.querySelectorAll('h2')[index].scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start', // 上边框与视窗顶部平齐
      });
    },
    //获得页面向左、向上卷动的距离
    getScroll() {
      return {
        left:
          window.pageXOffset ||
          document.documentElement.scrollLeft ||
          document.body.scrollLeft ||
          0,
        top:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0,
      };
    },

    // 滚动监听器
    handleScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('h2');
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      const scrollTop = this.getScroll().top;
      offsetTopArr.forEach((item, index) => {
        if (scrollTop >= item) {
          this.activeBtn = index;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.doc-content {
  display: flex;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  color: #3b454e;
  .content-left {
    width: calc(100% - 300px);
    .doc {
      max-width: 800px;
      margin: 0 auto;
      padding-bottom: 90px;

      .page {
        display: flex;
        margin: auto;
        padding-top: 24px;
        margin-top: 40px;
        border-top: 1px solid #e3e8ed;
        .previous,
        .next {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #e3e8ed;
          padding: 10px 20px;
          i {
            font-size: 30px;
          }
          .tip {
            color: #888;
            font-size: 10px;
          }
          &:hover {
            color: #f19403;
            box-shadow: 0px 12px 13px rgba(0, 0, 0, 0.02);
            transform: translateY(-2px);
            transition: 0.5s;
            .tip {
              color: #f19403;
            }
          }
        }
        .previous {
          &:not(:last-of-type) {
            margin-right: 20px;
          }
          > div {
            text-align: right;
          }
        }
      }
    }
  }

  .anchor {
    position: fixed;
    top: 130px;
    right: 100px;
    max-width: 200px;
    z-index: 999;

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      top: 8px;
      width: 1px;
      height: 96%;
      background: #e3e8ed;
    }
    .anchor-title {
      font-size: 13px;
      color: #8899a8;
      font-weight: bold;
      margin: 3px;
    }
    .step-ul {
      li {
        .step-label {
          font-size: 14px;
          line-height: 30px;
          color: #5c6975;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          &:hover{
            color: #f19403;
          }
        }
      }
      .step-active {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: -10px;
          top: 7px;
          width: 1px;
          height: 16px;
          background: #f19403;
        }
        .step-label {
          color: #f19403;
        }
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .content-left {
      width: 100%;
    }
    .anchor {
      display: none;
    }
  }
}
</style>
<style lang="scss">
.doc-content {
  .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 40px;
  }
  .h2 {
    color: #333;
    font-size: 22px;
    font-weight: bold;
    padding-top: 106px;
    margin-top: -96px;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 4px 0;
  }
  p {
    // text-indent: 2rem;
    margin-bottom: 14px;
  }
  .img-box {
    text-align: center;
    margin: 10px 0;
    img {
      max-width: 80%;
    }
    span {
      display: block;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
