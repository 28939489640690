<template>
  <div class="doc-page">
    <header class="header">
      <router-link class="logo" :to="`/doc/${$i18n.locale}/index`">
        <img src="~@/assets/home/logo1.png" width="54" />
        <span>{{ $t('doc.docTitle') }}</span>
      </router-link>
    </header>
    <div class="main">
      <MenuCommon class="menu-common"></MenuCommon>
      <Content class="main-content" />
    </div>
  </div>
</template>

<script>
import MenuCommon from './components/MenuCommon';
import Content from './content';
export default {
  name: 'DocIndex',
  components: { MenuCommon, Content },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.doc-page {
  position: relative;
  min-width: 1200px;
  .header {
    padding: 26px 50px;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
    position: sticky;
    top: 0;
    z-index: 2;

    .logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 20px;
      color: #555;
      span {
        margin-left: 8px;
      }
    }
  }
  .main {
    display: flex;
    box-sizing: border-box;
    // min-height: calc(100% - 160px);
    padding-top: 40px;
    position: relative;
    top: 0;

    .menu-common {
      box-sizing: border-box;
      width: 300px;
      height: 80vh;
      padding-left: 50px;
      position: sticky;
      top: 132px;
      z-index: 1;
      border-right: solid 1px #e6e6e6;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .main-content {
      box-sizing: border-box;
      width: calc(100% - 300px);
    }
  }
}
</style>