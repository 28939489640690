export const menuItemData = [
  {
    index: 'index',
    title: 'BNS背景',
  },
  {
    index: 'idea',
    title: 'BNS理念',
  },
  {
    index: 'namespace',
    title: 'BNS名字空间',
  },
  {
    index: 'function',
    title: 'BNS注册解析和应用',
  },
  {
    index: 'governance',
    title: 'BNS治理',
  },
  {
    index: 'outlook',
    title: 'BNS展望',
  },
];
